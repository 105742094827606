.structured-text

  em
    --tw-text-opacity: .6

  strong
    color: inherit
    font-weight: 500
    --tw-text-opacity: 1

  u
    color: inherit

