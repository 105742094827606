@import "tailwindcss/base"
@import "tailwindcss/components"
@import "tailwindcss/utilities"
@import "tailwind-default"

@import "typography"

@import "~tocbot/src/scss/_tocbot-core.scss"

@import "_tocbot-custom"

@import "tailwind/forms"

@import "structured-text"

*
  -webkit-tap-highlight-color: transparent