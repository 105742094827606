*, *::before, *::after
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  -webkit-tap-highlight-color: transparent

@font-face
  font-family: "Buenos Aires"
  font-style: normal
  font-weight: 300
  src: url("https://static.piggy.nl/website/fonts/buenosaires-book-alt.woff2") format("woff2")
  src: url("https://static.piggy.nl/website/fonts/buenosaires-book-alt.woff") format("woff")

@font-face
  font-family: "Buenos Aires"
  font-style: italic
  font-weight: 300
  src: url("https://static.piggy.nl/website/fonts/buenosaires-bookitalic-alt.woff2") format("woff2")
  src: url("https://static.piggy.nl/website/fonts/buenosaires-bookitalic-alt.woff") format("woff")

@font-face
  font-family: "Buenos Aires"
  font-style: normal
  font-weight: 400
  src: url("https://static.piggy.nl/website/fonts/buenosaires-regular-alt.woff2") format("woff2")
  src: url("https://static.piggy.nl/website/fonts/buenosaires-regular-alt.woff") format("woff")

@font-face
  font-family: "Buenos Aires"
  font-style: italic
  font-weight: 400
  src: url("https://static.piggy.nl/website/fonts/buenosaires-regularitalic-alt.woff2") format("woff2")
  src: url("https://static.piggy.nl/website/fonts/buenosaires-regularitalic-alt.woff") format("woff")

@font-face
  font-family: "Buenos Aires"
  font-style: normal
  font-weight: 500
  src: url("https://static.piggy.nl/website/fonts/buenosaires-semibold-alt.woff2") format("woff2")
  src: url("https://static.piggy.nl/website/fonts/buenosaires-semibold-alt.woff") format("woff")

@font-face
  font-family: "Buenos Aires"
  font-style: italic
  font-weight: 500
  src: url("https://static.piggy.nl/website/fonts/buenosaires-semibolditalic-alt.woff2") format("woff2")
  src: url("https://static.piggy.nl/website/fonts/buenosaires-semibolditalic-alt.woff") format("woff")

@font-face
  font-family: "Buenos Aires"
  font-style: normal
  font-weight: 700
  src: url("https://static.piggy.nl/website/fonts/buenosaires-bold-alt.woff2") format("woff2")
  src: url("https://static.piggy.nl/website/fonts/buenosaires-bold-alt.woff") format("woff")

@font-face
  font-family: "Buenos Aires"
  font-style: italic
  font-weight: 700
  src: url("https://static.piggy.nl/website/fonts/buenosaires-bolditalic-alt.woff2") format("woff2")
  src: url("https://static.piggy.nl/website/fonts/buenosaires-bolditalic-alt.woff") format("woff")
