@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


.hubspot-form-block form, form {
  @apply flex-grow flex-1;
}

.hubspot-form-block input, .hubspot-form-block textarea, input {
  @apply p-3 lg:p-4 my-1 font-light rounded-lg border text-purple-tint-dark border-purple-tint-light w-full lg:my-1.5;
}

.hubspot-form-block button[type=submit], button[type=submit] {
  @apply inline-flex items-center transition-all duration-300 justify-center rounded-xl py-3.5 lg:px-5 px-8 text-lg bg-purple text-white bg-purple w-full mt-1 justify-center lg:mt-4;
}

.hubspot-form-block fieldset {
  max-width: unset !important;
  @apply mb-3;
}

.hubspot-form-block .hs-input {
  width: 100% !important;
}

.hubspot-form-block .hs-error-msg {
  @apply text-red mb-4 inline-block;
}

.hubspot-form-block .submitted-message {
  text-align: center !important;
}

.hubspot-form-block .hs-button {
  @apply bg-primary-600 text-white hover:bg-primary-700 cursor-pointer font-medium transition-all ease-in-out duration-200 mt-4;
}

.hubspot-form-block .submitted-message {
  display: none;
}