.toc-link  {
  @apply text-base;
  @apply font-normal;
  @apply pb-5;
  @apply block;
}

.toc-list-item {
  @apply text-gray-500
}

.is-active-link {
  @apply font-medium;
  @apply text-purple-tint-dark;
}

.is-active-link::before {
  @apply bg-purple-tint-dark;
}
